.sidebar-menu {
      border-right: solid 1px #e6e6e6 !important;
      margin-top: 10px;

      .ant-menu-title-content {
            a {
                  text-decoration: none;
                  color: #303133;
            }
      }

      .ant-menu-item-selected {
            color: #ff7731 !important;
            border: 1px solid #ff7731 !important;
            border-radius: 10px !important;
            background-color: #ffefe7 !important;
            text-underline-offset: 8px !important;
            text-decoration-color: #ff7f00 !important;

            .ant-menu-title-content a {
                  color: #ff7731 !important;
            }

            .ant-menu-submenu-arrow {
                  fill: #ff7731;
            }
      }

      .ant-menu-item {
            height: 50px;
      }

      .ant-menu-submenu-selected {
            .ant-menu-title-content {
                  color: #ff7731 !important;
            }

            svg {
                  fill: #ff7731;
            }
      }
      .ant-menu-submenu-title {
            a {
                  font-weight: 500;
            }
      }
}