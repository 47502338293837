.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
        color: #ff7731 !important;
    }
}
.icon-prefix, .ant-input-prefix {
    color: #C0C4CC;
}
.ant-space-item, th {
    font-weight: 500 !important;
    color: #60627a;
}
.ant-btn-primary.ant-btn-dangerous {
    background: #ff4d4f !important;
}
// .switch {
//     background: rgb(19, 206, 102) !important;
// }
.ant-modal-title {
    font-weight: 400 !important;
}
// .switch.ant-switch.ant-switch-checked {
//     background-color: rgb(19, 206, 102) !important;
// }
.taifile {
    span {
        font-weight: 500;
    }
}
.text-eye {
    color: rgb(19, 206, 102);
    &:hover {
        color: rgb(19, 206, 102) !important;
    }
}
.ant-select-selection-search-input {
    border: 0 !important;
    &:hover {
        border: 0 !important;
        box-shadow: none !important;
    }
}
.bg-green {
    background:rgb(19, 206, 102);
}
.border-green {
    border-color:rgb(19, 206, 102);
}