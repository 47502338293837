.ant-picker {
  width: 350px;
  height: 32px;
  align-items: center;
  padding: 3px 10px;
  border-radius: 4px;
  border: 1px solid #99a0b1;

  .ant-picker-input {
    input {
      border: none !important;
      box-shadow: none;
      font-size: 12px;
    }
  }
}
.ant-picker:hover {
  border-color: #ff7731 !important;
  box-shadow: 0px 0px 6px 0px #ff7731;
}
.ant-picker-focused {
  border: none !important;
}
.ant-picker-input-active {
  border: none !important;
}
.ant-picker-active-bar {
  display: none;
}
.ant-picker-range-separator {
  padding: 0 16px !important;
}
.ant-picker-focused {
  border-color: #ff7731 !important;
  box-shadow: 0px 0px 6px 0px #ff7731 !important;
}

.site-card-border-less-wrapper {
  padding: 20px;
  color: #303133;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  transition: 0.3s;
  border-radius: 4px;
  overflow: hidden;
  width: 32%;
  p,
  b {
    text-align: center;
    margin: 0;
  }
}
.chart-container {
  margin-top: 30px;
}
