@import url("https://fonts.googleapis.com/css2?family=Island+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://unpkg.com/browse/antd@5.0.0/dist/reset.css");

:root {
  --primary-color: #ff7731;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Montserrat", sans-serif;
  --subtitle-font-name: "Island Moments", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);
}

// section {
//   padding: 50px 0px;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}

.ant-layout-sider-children {
  position: fixed;
  top: 50px;
}

.ant-layout {
  background-color: #ffff !important;
}

.ant-btn-primary {
  background-color: #ff7731;
  border-color: #ff7731;
  border-radius: 6px !important;
  &:hover {
    background-color: #ff7731 !important;
    border-color: #ff7731 !important;

    span {
      color: white;
    }
  }
}

// .ant-btn {
//   background-color: #ff7731 !important;
//   border-color: #ff7731;
//   border-radius: 10px !important;
//   color: white;

//   &:hover {
//     background-color: #ff7731 !important;
//     border-color: #ff7731 !important;

//     span {
//       color: white;
//     }
//   }

// }

.btn {
  &--danger {
    background-color: #f56c6c !important;
    border-color: #f56c6c !important;
    color: white;
    &:hover {
      background-color: #f56c6c !important;
      border-color: #f56c6c !important;
    }
  }

  &--timer {
    background-color: #e6a23c !important;
    border-color: #e6a23c !important;
    &:hover {
      background-color: #e6a23c !important;
      border-color: #e6a23c !important;
    }
  }
}

.ant-tag {
}

// Ant Table
.ant-table {
  th {
    background: #fff !important;
    border-radius: 0;
  }

  .ant-table-selection-column {
    width: 55px;
    border-left: 1px solid #ebeef5 !important;
    border-right: none !important;
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-cell:last-child {
    border-right: 1px solid #ebeef5 !important;
  }

  .ant-table-thead > tr > th {
    border: 1px solid #ebeef5;

    border-radius: 0 !important;
    font-weight: 500;
    color: #60627a;
  }

  .ant-table-cell {
    border: 1px solid #ebeef5;

    border-radius: 0 !important;
    font-size: 14px;
    color: #606266;
  }
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-y8x9xp).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #fff !important;
}

a {
  color: #ff7731;
}

// Ant tabs
:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs {
  border-radius: 10px !important;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ff7731;
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab,
:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab {
  border-radius: 8px 0 0 0;
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs .ant-tabs-tab:hover {
  color: #ff7731;
}

.ant-tabs-nav-wrap {
  background: rgba(0, 0, 0, 0.02);
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs .ant-tabs-tabpane {
  padding: 0 14px 14px;
}

:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-bottom
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
:where(.css-dev-only-do-not-override-y8x9xp).ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0;
}
.cusor-pointer {
  cursor: pointer;
}

canvas {
  max-height: 580px !important;
}
.ant-tabs-nav-wrap {
  border-radius: 8px 8px 0 0;
}
