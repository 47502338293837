.customDropdown {
      .ant-dropdown-menu-title-content {
            color: #ff7731 !important;
      }
}
.modalchangepassword {
      .ant-btn-primary {
            background: #ff7731 !important;
            box-shadow: none;
      }
}
