.ant-radio-button-wrapper-checked {
    color: #FFF !important;
    background-color: #409EFF !important;
    border-color: #409EFF !important;
}

.btn-dowload {
    color: #909399;
    background-color: #f4f4f5 !important;
    border-color: #d3d4d6 !important;
}
.ant-btn-primary.ant-btn-dangerous {
    background: #ff4d4f !important;
}
.ant-switch.ant-switch-checked {
    background-color: #409EFF !important;
}