.btn-litmit-time {
  color: #f56c6c !important;
  background: #fef0f0 !important;
  border-color: #fbc4c4 !important;

  &:hover {
    background: #f56c6c !important;
    border-color: #f56c6c !important;
    color: #fff !important;
  }
}

.btn-password {
  color: #fff;
  background-color: #909399 !important;
  border-color: #909399 !important;
}

.ant-popconfirm-buttons {
  .ant-btn-default {
    background: var(--ant-button-default-bg);
    border-color: var(--ant-button-default-border-color);
    color: var(--ant-button-default-color);
  }
}
.row-disable {
  background: #ecf5ff;
}
.formCreateUser {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.custom-switch.ant-switch-checked {
    background-color: #ff7731 !important;
}
