$primary: #ff7731;
$cl-label: #606266;
$blue: #409eff;
$cancel: var(--ant-button-default-bg);

* {
  font-family: Montserrat;
  font-weight: 300;
}

body {
  color: #000;
}

label,
span,
button,
input,
div,
a {
  font-family: Montserrat !important;
}

.text-primary {
  color: $primary !important;
}

.bg-primary {
  background: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.bg-blue {
  background: $blue !important;
}

.border-blue {
  border-color: $blue !important;
}

.text-blue {
  color: $blue !important;
}

.bg-cancel {
  background: $cancel !important;
}

.border-cancel {
  border-color: #d9d9d9 !important;
}

.text-label {
  color: $cl-label;
}

$sizes: 12, 14, 16, 18, 20, 22, 24, 26, 28, 30;

@each $size in $sizes {
  .text-#{$size} {
    font-size: #{$size}px !important;
  }
}

$weights: 400, 500, 600, 700;

@each $weight in $weights {
  .font-#{$weight} {
    font-weight: #{$weight};
  }
}

.rounded-4 {
  border-radius: 4px !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

input,
.ant-select-selector, textarea {
  border: 1px solid #99a0b1 !important;
  border-radius: 6px !important;

  &:hover {
    border-color: #ff7731 !important;
    box-shadow: 0px 0px 6px 0px #ff7731;
  }

  &::placeholder {
    color: #e0e1e5 !important;
  }
}

.ant-input-affix-wrapper {
  border: 1px solid #99a0b1 !important;

  &:hover {
    border-color: #ff7731 !important;
    box-shadow: 0px 0px 6px 0px #ff7731;
  }

  input {
    border: 0 !important;

    &:hover {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.ant-tag-has-color {
  font-size: 12px !important;
  border-radius: 4px;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-divider {
  border-block-start: 1px solid #dcdfe6;
}

.card {
  border-radius: 10px !important;
  border: 1px solid #DCDFE6;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  background-color: #f4f4f5 !important;
}

.ant-pagination-item-active {
  background-color: #ff7731 !important;
  border-color: #ff7731 !important;
  color: #fff;
}

.custome-tab.ant-tabs-nav {
  border-radius: 10px 10px 0 0;
}

.ant-tabs-tab {
  border: 0 !important;
}

.ant-btn-primary:hover {
  background: #ff7731 !important;
}